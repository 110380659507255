import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
//import { Link } from "gatsby";
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import FeaturesSec from "~sections/features/FeaturesSec"
import Testimonial from "~sections/customer-stories/Testimonial"
import { Helmet } from "react-helmet";
import Hero from "~sections/features/Hero/Hero"
import LnAutomation from "~sections/features/Sections/ln-automation"
import Emial from "~sections/features/Sections/email-automation"
import Digest from "~sections/features/Sections/ai-digest"
import Grad from "~sections/grad-section/grad"
//import scrollTo from 'gatsby-plugin-smoothscroll';
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Features() {
  return (
    <>
    <Helmet>
      <title>We-Connect | Features</title>
        <meta name="description" content="With 30+ features designed to save you time and money, We-Connect is the most advanced LinkedIn automation software on the market. Boost your conversion today!"/>
        <meta property="og:title" content="We-Connect | Features"/>
        <meta property="og:description" content="With 30+ features designed to save you time and money, We-Connect is the most advanced LinkedIn automation software on the market. Boost your conversion today!"/>
        <meta property="og:image" content="https://we-connect.io/we-connect_features-page.png"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://we-connect.io/features"/>
        <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
        <meta property="twitter:title" content="We-Connect | Features"/>
        <meta property="twitter:description" content="With 30+ features designed to save you time and money, We-Connect is the most advanced LinkedIn automation software on the market. Boost your conversion today!"/>
        <meta property="twitter:image" content="https://we-connect.io/we-connect_features-page.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <Hero />
      <LnAutomation />
      <Emial />
      <Digest />
      <FeaturesSec />
      <Grad />
      <Testimonial />  
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}
