import styled from 'styled-components/macro';
import {transparentize} from "polished"
import {
    Box,
    Heading,
    Button,
    Paragraph
} from '~styled';
const propToColor = (arg = "#442cd6" ) => transparentize(0.70, arg);
const Feature = styled(Box)
`
padding-top: 20px;
padding-bottom: 20px;
background-color: #F3F4F6;
@media (min-width: 768px){
    padding-top: 60px;
    padding-bottom: 60px;
}
@media (min-width: 992px){
    padding-top: 100px;
    padding-bottom: 100px;
}


`
Feature.Title = styled(Heading)
`
font-size: 32px;
    font-weight: 500;
    text-align: center;
    letter-spacing: normal;
    line-height: 1.2;
    color: #262729;
    margin-top: 60px;
    margin-bottom: 40px;
    @media (min-width:768px) {
        font-size: 35px;
    }

    @media (min-width:992px) {
        font-size: 45px;
    }
`
Feature.TitleSmall = styled(Heading)
`
font-size: 21px;
font-weight: 500;
letter-spacing: normal;
line-height: 1.42857;
margin-bottom: 15px;
@media (min-width: 992px){
    margin-bottom: 28px;
}
`
Feature.SubTitle = styled(Heading)
`
font-size: 16px;
font-weight: 500;
letter-spacing: normal;
line-height: 1.42857;
margin-bottom: 15px;
@media (min-width: 992px){
    margin-bottom: 16px;
}
`
Feature.Text = styled(Paragraph)
`
font-size: 16px;
font-weight: 400;
letter-spacing: normal;
line-height: 1.777777;
@media (min-width: 992px){
    font-size: 18px;
}
`
Feature.List = styled(Box)
``


Feature.ListItem = styled(Paragraph)
`
font-size: 16px;
font-weight: 400;
letter-spacing: normal;
line-height: 1.777777;
@media (min-width: 992px){
    font-size: 18px;
}
`
Feature.ContentBox = styled(Box)
`
padding-top: 10px;
padding-bottom: 20px;
`
Feature.Content = styled(Box)`
@media (min-width: 1200px){
    padding: 0 43px;
    margin-top: 60px;
}
@media (min-width: 1400px){
    padding: 0 53px;
    margin-top: 60px;

}
`
Feature.Button = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    transition:.4s;
    margin-bottom: 40px;
    border-box: 10px;
    border-color: grey;
    
  &:hover{
    transform: translateY(-5px);
    border-color: grey;
  }
`
Feature.Box = styled(Box)
``

export default Feature;