import React from 'react'
import Content from '../Styles/styleTwo'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import img from "~assets/image/media/AiAssist/svg/Hero-AI-Digest.svg";

export default function Digest({}){

return(
<Content>
  
  <Container>
    <Content.Block>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
    <Content.Box>
          <Content.SubText as="h2"> <SuperTag value="AI Assist"/> </Content.SubText>

          <Content.Text> <SuperTag value="Integrating various tools and platform is crucial for streamlining workflow and boosting productivity. Discover how We-Connect integration can revoluntionize your processes, saving you time and effort while maximizing results."/> </Content.Text>
          <Link to="/ai-digest"><Content.Button  className="btn btn-primary text-white">Learn more</Content.Button></Link>
        </Content.Box>
      </Col>
      <Col xs="12" className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
      <Content.Image>
            <img  src={img} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" height={520}/>
        </Content.Image>
      </Col>
    </Row>
    </Content.Block>

    
  </Container>
</Content>
)
}