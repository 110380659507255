import React from 'react'
import Content from './style'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';

export default function Grad({}){

return(
<Content className='grad'>
  
  <Container>
    <Content.Block>
    <Row className="justify-content-center text-center">
    <Col xs="12">
    <Content.Title as="h2"> <SuperTag value="We-Connect unlocks hidden value inside your LinkedIn account."/> </Content.Title>
    <Content.Button target="_blank" to="//account.we-connect.io/signup" className="btn btn-white">See our features in action</Content.Button>
      </Col>
    </Row>
    </Content.Block>

    
  </Container>
</Content>
)
}