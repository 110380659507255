import styled from 'styled-components/macro';
import { Box, Heading,Button,Paragraph } from '~styled';
import { Link } from '~components';

const Hero = styled(Box)`
    padding-top: 100px;
    padding-bottom: 60px;
    position: relative;

    @media (min-width: 768px){
        padding-top: 140px;
        padding-bottom: 60px;
    }

    @media (min-width: 992px){
        padding-top: 150px;
        padding-bottom: 60px;
    }

    @media (min-width: 1200px){
        padding-top: 170px;
        padding-bottom: 60px;
    }

    @media (min-width: 1366px){
        padding-top: 160px;
        padding-bottom: 160px;
    }

`

Hero.Title = styled(Heading)`
font-weight: 500;
text-align: center;
letter-spacing: -2px;
line-height: 1.1;
font-size: 40px;
margin-bottom: 25px;
z-index: 1;
position: relative;

@media (min-width: 480px) {
    font-size: 40px;
}

@media (min-width: 768px){
    font-size: 50px;
}

@media (min-width: 992px){
    font-size: 51px;
}
@media (min-width: 1200px){
    font-size: 60px;
}
`

Hero.Text = styled(Paragraph)`
color: #000;
font-size: 18px;
font-weight: 500;
text-align: center;
letter-spacing: normal;
line-height: 34px;

@media (min-width: 768px){
    font-size: 21px;
}
`

Hero.ContentBlock = styled(Box)`
    
`
Hero.Box = styled(Box)`

`
export default Hero;