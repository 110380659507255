import styled from 'styled-components/macro';
import { Box, Heading,Button,Paragraph } from '~styled';

const Widget = styled(Box)`
margin-bottom: 40px;
@media (min-width: 992px){
    margin-bottom: 30px;
    
}

@media (min-width: 1200px){
    padding-right: 10px;
}

@media (min-width: 1600px) {
    padding-right: 10px;
}
display: block;

}
`

Widget.Img = styled(Box)`
    margin-right: 10px;
    height: 80px;
    width: 80px;
    @media (max-width: 771px){
        height: 40px;
        width: 40px;
    }
`
Widget.Content = styled(Box)`
    
`
Widget.Title = styled(Heading)`
    font-size: 18px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 30px;
    @media (max-width: 771px){
        font-size: 12px;
    }
    @media (max-width: 1200px){
        margin-left: 12px;
    }
    @media (max-width: 992px){
        margin-left: 32px;
        font-size: 15px;
    }
`
Widget.Text = styled(Paragraph)`
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 30px;
    float: right;
    padding: 6px;
    @media (max-width: 991px){
        font-size: 13px;
    }
`
Widget.Box = styled(Box)`
background-color: #fff;
border: solid 1px #e2e4e8;
border-radius: 10px;
margin-bottom: 40px;
@media (max-width: 991px){
    margin-bottom: 5px;
    padding-right: 10px;
    padding-top: 10px;
    padding-left: 10px;
    width: auto;
    height: auto;
}
@media (min-width: 992px){
    margin-bottom: 10px;
    padding-right: 20px;
    padding-top:20px;
    padding-bottom: 20px;
    padding-left: 20px;
    height: 300px;
}

@media (min-width: 1200px){
    padding-right: 10px;
}

@media (min-width: 1600px) {
    padding-right: 10px;
}
display: inline-block;

}
`


export default Widget;