import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Feature from './style'
import FeaturesWidget2 from './components/FeaturesWidgetTwo/FeaturesWidget2';
import featuresData from '~data/features/FeaturesData';
import 'react-accessible-accordion/dist/fancy-example.css';
const postsPerPage = 1;
let arrayForHoldingPosts = [];
import 'react-accessible-accordion/dist/fancy-example.css';


export default function FeaturesSec({backgroundColor} ){
    const [featuresDataToShow, setFeaturesDataToShow] = useState([]);
  const [next, setNext] = useState(1);

  const loopWithSlice = (start, end) => {
    const slicedPosts = featuresData.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setFeaturesDataToShow(arrayForHoldingPosts);
  };

  useEffect(() => {
    loopWithSlice(0, postsPerPage);
  }, []);

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage );
    setNext(next + postsPerPage);
  };
return(
      
<Feature>
  <Container>
    <Row className="justify-content-center mb-6">
      <Col className="col-xl-7 col-lg-8 col-md-11 text-center">
        <Feature.Box>
          <Feature.Title as="h1">See all features</Feature.Title>
        </Feature.Box>
      </Col>
    </Row>
    <FeaturesWidget2 />
  </Container>
</Feature>

)
}