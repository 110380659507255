import React from "react"
import { SuperTag } from "~components"
import { Col, Row } from 'react-bootstrap'
import Widget2 from './style'
import Widget from "../style"
import featuresData from "~data/features/FeaturesData"
const FeaturesWidget = ({  }) => {return (
  
    <Widget2>
      {featuresData.map((feature, index) => (
      <Row>
      <Col className="col-md-4 col-xs-8" key={index}>
          <Widget.Box>
          <Row>
            <Col className="col-3">
            <Widget.Img>
              <img src={feature.img1} className="w-100"/>
            </Widget.Img>
          </Col>
          <Col className="pt-2">
          <Widget.Title mb="10px" fontColor="#262729">
                {feature.title1}
          </Widget.Title>
          </Col>
          </Row>
          
          <Widget.Text fonColor="#747474">
                <SuperTag value={feature.text1}/>
          </Widget.Text>
        </Widget.Box> 
        <Widget.Box>
          
          <Row>
            <Col className="col-3">
            <Widget.Img>
              <img src={feature.img2} className="w-100"/>
            </Widget.Img>
          </Col>
          <Col className="pt-2">
          <Widget.Title mb="10px" fontColor="#262729">
                {feature.title2}
          </Widget.Title>
          </Col>
          </Row>
          
          <Widget.Text fonColor="#747474">
                <SuperTag value={feature.text2}/>
          </Widget.Text>
        </Widget.Box> 
        </Col>
          
        <Col className="col-md-4 col-xs-8">
        <Widget2.Box>
          <Row>
            <Col className="col-3">
            <Widget2.Img>
              <img src={feature.img3} className="w-100"/>
            </Widget2.Img>
          </Col>
          <Col className="pt-2">
          <Widget2.Title mb="10px" fontColor="#262729">
                {feature.title3}
          </Widget2.Title>
          </Col>
          </Row>
          
          <Widget2.Text fonColor="#747474">
                <SuperTag value={feature.text3}/>
          </Widget2.Text>
          </Widget2.Box>       
                    
         </Col>
                  
        <Col className="col-md-4 col-xs-8" >
            
            <Widget.Box>
          
          <Row>
            <Col className="col-3">
            <Widget.Img>
              <img src={feature.img4} className="w-100"/>
            </Widget.Img>
          </Col>
          <Col className="pt-2">
          <Widget.Title mb="10px" fontColor="#262729">
                {feature.title4}
          </Widget.Title>
          </Col>
          </Row>
          
          <Widget.Text fonColor="#747474">
                <SuperTag value={feature.text4}/>
          </Widget.Text>
        </Widget.Box> 
        <Widget.Box>
          
          <Row>
            <Col className="col-3">
            <Widget.Img>
              <img src={feature.img5} className="w-100"/>
            </Widget.Img>
          </Col>
          <Col className="pt-2">
          <Widget.Title mb="10px" fontColor="#262729">
                {feature.title5}
          </Widget.Title>
          </Col>
          </Row>
          
          <Widget.Text fonColor="#747474">
                <SuperTag value={feature.text5}/>
          </Widget.Text>
        </Widget.Box> 
             
        </Col>
        <Col className="col-md-4 col-xs-8">
        <Widget2.Box>
          <Row>
            <Col className="col-3">
            <Widget2.Img>
              <img src={feature.img6} className="w-100"/>
            </Widget2.Img>
          </Col>
          <Col className="pt-2">
          <Widget2.Title mb="10px" fontColor="#262729">
                {feature.title6}
          </Widget2.Title>
          </Col>
          </Row>
          
          <Widget2.Text fonColor="#747474">
                <SuperTag value={feature.text6}/>
          </Widget2.Text>
          </Widget2.Box>       
                    
         </Col>

         <Col className="col-md-4 col-xs-8" >
            
            <Widget.Box>
          
          <Row>
            <Col className="col-3">
            <Widget.Img>
              <img src={feature.img7} className="w-100"/>
            </Widget.Img>
          </Col>
          <Col className="pt-2">
          <Widget.Title mb="10px" fontColor="#262729">
                {feature.title7}
          </Widget.Title>
          </Col>
          </Row>
          
          <Widget.Text fonColor="#747474">
                <SuperTag value={feature.text7}/>
          </Widget.Text>
        </Widget.Box> 
        <Widget.Box>
          
          <Row>
            <Col className="col-3">
            <Widget.Img>
              <img src={feature.img8} className="w-100"/>
            </Widget.Img>
          </Col>
          <Col className="pt-2">
          <Widget.Title mb="10px" fontColor="#262729">
                {feature.title8}
          </Widget.Title>
          </Col>
          </Row>
          
          <Widget.Text fonColor="#747474">
                <SuperTag value={feature.text8}/>
          </Widget.Text>
        </Widget.Box> 
             
        </Col>

        <Col className="col-md-4 col-xs-8" >
            
            <Widget.Box>
          
          <Row>
            <Col className="col-3">
            <Widget.Img>
              <img src={feature.img9} className="w-100"/>
            </Widget.Img>
          </Col>
          <Col className="pt-2">
          <Widget.Title mb="10px" fontColor="#262729">
                {feature.title9}
          </Widget.Title>
          </Col>
          </Row>
          
          <Widget.Text fonColor="#747474">
                <SuperTag value={feature.text9}/>
          </Widget.Text>
        </Widget.Box> 
        <Widget.Box>
          
          <Row>
            <Col className="col-3">
            <Widget.Img>
              <img src={feature.img10} className="w-100"/>
            </Widget.Img>
          </Col>
          <Col className="pt-2">
          <Widget.Title mb="10px" fontColor="#262729">
                {feature.title10}
          </Widget.Title>
          </Col>
          </Row>
          
          <Widget.Text fonColor="#747474">
                <SuperTag value={feature.text10}/>
          </Widget.Text>
        </Widget.Box> 
             
        </Col>

        <Col className="col-md-4 col-xs-8" >
            
            <Widget.Box>
          
          <Row>
            <Col className="col-3">
            <Widget.Img>
              <img src={feature.img11} className="w-100"/>
            </Widget.Img>
          </Col>
          <Col className="pt-2">
          <Widget.Title mb="10px" fontColor="#262729">
                {feature.title11}
          </Widget.Title>
          </Col>
          </Row>
          
          <Widget.Text fonColor="#747474">
                <SuperTag value={feature.text11}/>
          </Widget.Text>
        </Widget.Box> 
        <Widget.Box>
          
          <Row>
            <Col className="col-3">
            <Widget.Img>
              <img src={feature.img12} className="w-100"/>
            </Widget.Img>
          </Col>
          <Col className="pt-2">
          <Widget.Title mb="10px" fontColor="#262729">
                {feature.title12}
          </Widget.Title>
          </Col>
          </Row>
          
          <Widget.Text fonColor="#747474">
                <SuperTag value={feature.text12}/>
          </Widget.Text>
        </Widget.Box> 
             
        </Col>

        <Col className="col-md-4 col-xs-8" >
            
            <Widget.Box>
          
          <Row>
            <Col className="col-3">
            <Widget.Img>
              <img src={feature.img13} className="w-100"/>
            </Widget.Img>
          </Col>
          <Col className="pt-2">
          <Widget.Title mb="10px" fontColor="#262729">
                {feature.title13}
          </Widget.Title>
          </Col>
          </Row>
          
          <Widget.Text fonColor="#747474">
                <SuperTag value={feature.text13}/>
          </Widget.Text>
        </Widget.Box> 
        <Widget.Box>
          
          <Row>
            <Col className="col-3">
            <Widget.Img>
              <img src={feature.img14} className="w-100"/>
            </Widget.Img>
          </Col>
          <Col className="pt-2">
          <Widget.Title mb="10px" fontColor="#262729">
                {feature.title14}
          </Widget.Title>
          </Col>
          </Row>
          
          <Widget.Text fonColor="#747474">
                <SuperTag value={feature.text14}/>
          </Widget.Text>
        </Widget.Box> 
             
        </Col>

        <Col className="col-md-4 col-xs-8">
        <Widget2.Box>
          <Row>
            <Col className="col-3">
            <Widget2.Img>
              <img src={feature.img15} className="w-100"/>
            </Widget2.Img>
          </Col>
          <Col className="pt-2">
          <Widget2.Title mb="10px" fontColor="#262729">
                {feature.title15}
          </Widget2.Title>
          </Col>
          </Row>
          
          <Widget2.Text fonColor="#747474">
                <SuperTag value={feature.text15}/>
          </Widget2.Text>
          </Widget2.Box>       
                    
         </Col>
      </Row>
        ))}
      </Widget2>
  );
};
export default FeaturesWidget;