import React from 'react'
//import { StaticImage as  Img } from "gatsby-plugin-image";
import Hero from './style'
import { Col, Container, Row } from 'react-bootstrap';
//import { SuperTag } from '~components';
export default function HeroSection(){
  return(
<Hero className="position-relative" backgroundColor="#f3f4f6">
  <Container>
  <Row className="align-items-end justify-content-center">
      <Col xs="12">
        <Hero.ContentBlock mb="35px" className="text-center text-lg-start">
        <Hero.Title as="h1">
                  Features
        </Hero.Title>          
                <Hero.Text className="mt-4">
                Design to maximize the productivity and safety of your account.<br className="d-none d-xs-none d-lg-none d-xl-block" /> Prospect with confidence with the most advanced yet easy to use LinkedIn software. 
                </Hero.Text>
        </Hero.ContentBlock>
      </Col>
    </Row>
  </Container>
  
</Hero>
)
}