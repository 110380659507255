import img1 from "~assets/image/features/03-MessageGroups.svg";
import img2 from "~assets/image/features/02-FreeInmails.svg";
import img3 from "~assets/image/features/Smart-sequences.svg";
import img4 from "~assets/image/features/06-MyContacts.svg";
import img5 from "~assets/image/features/05-ClientManage.svg";
import img7 from "~assets/image/features/AI Writer.svg";
import img19 from "~assets/image/features/04-SyncData.svg";
import img8 from "~assets/image/features/LinkedIn events-01.svg";
import img9 from "~assets/image/features/LinkedIn Post-01.svg";
import img10 from "~assets/image/features/Sync LinkedIn contacts-01.svg";
import img28 from "~assets/image/features/Custom Variables.svg";
import img15 from "~assets/image/features/Interactive Inbox.svg";
import img13 from "~assets/image/features/Reply Detection.svg";
import img14 from "~assets/image/features/Resume Sequence.svg";
import img12 from "~assets/image/features/Run Multiple Campaigns.svg";
import img16 from "~assets/image/features/AB Test.svg";
import img17 from "~assets/image/features/Export Saved Lists.svg";
import img20 from "~assets/image/features/Schedule Time Zones.svg";
import img6 from "~assets/image/features/Celebration greetings-01.svg";
import img18 from "~assets/image/features/Tag Contacts _ Message.svg";
import img21 from "~assets/image/features/Email Notifications.svg";
import img22 from "~assets/image/features/Saved Replies.svg";
import img23 from "~assets/image/features/Auto Contact Suppression.svg";
import img24 from "~assets/image/features/Archive Campaigns.svg";
import img25 from "~assets/image/features/Clone Campaigns.svg";
import img26 from "~assets/image/features/Auto Withdraw Linkedin Invites.svg";
import img27 from "~assets/image/features/Blacklist Contacts.svg";
import img30 from "~assets/image/features/Write notes to contact.svg";
import img29 from "~assets/image/features/Activity Log.svg";
import img11 from "~assets/image/features/Randomize Campaign Actions.svg";


const featuresData = [
    {
        order: 'first',
        img1: img1,
        title1:"Message Groups",
        text1:"Send the messages to the members of LinkedIn groups without sending a connection request.",
        img2: img2,
        title2:"Send Free InMails",
        text2:"Skip inviting new contacts and waiting for them to accept your invite. Instead, straightway send free InMails to members with open profiles using We-Connect. You may get the same or better reply rate compared to an Invite campaign.",
        img3: img3,
        title3:"Smart Sequences",
        text3:"Design one campaign that does it all from visiting a profile, liking a post, inviting to connect and then following the contact. Tell our bot what to do if an invite is not accepted like visit profile again and / or like a post again to get your prospects attention.",
        img4: img4,
        title4:"My Contacts",
        text4:"Monitor everything from one place. Track all your contacts. View who is invited, connected, pending, where they are in the sequence. Search by name or sort by stage. Tag or pause mulitple contacts.",
        img5: img5,
        title5:"Client Management",
        text5:"Manage multiple clients campaigns from a single dashboard with a single sign on.",
        img6: img6,
        title6:"Celebration greetings",
        text6:"Automatically message to wish happy birthday! Automatically message to congratulate on job anniversary. Automatically message to congratulate on a new role.",
        img7: img7,
        title7:"AI Writer",
        text7:"Generate LinkedIn messaging content 10X faster with our AI writer.",
        img8: img8,
        title8:"LinkedIn events",
        text8:"Automatically get the lists of event attendees and send an invite, visit, follow etc. campaigns on We-Connect.",
        img9: img9,
        title9:"LinkedIn Post",
        text9:"Automatically get the lists of people that like a post on Linkedin and send an invite, visit, follow etc. campaigns on We-Connect.",
        img10: img10,
        title10:"Sync LinkedIn contacts",
        text10:"Sync the contacts on your email to LinkedIn and import it to send invites with follow up messages.",
        img11: img11,
        title11:"Randomize campaign actions",
        text11:"Our software automatically randomizes the delay between invites and messages. This feature helps to emulate human behavior and makes it impossible to detect a machine pattern and increase the safety of your account.",
        img12: img12,
        title12:"Run multiple campaigns",
        text12:"Create and run multiple campaigns at the same time.",
        img13: img13,
        title13:"Reply detection",
        text13:"You don't need to stop a message sequence when you get a reply. We-Connect detects the response and automatically stops the message sequence.",
        img14: img14,
        title14:"Resume sequence",
        text14:"In the situations when you get an unclear response and the sequence stops, you have the option to resume the sequence in we-connect manually.",
        img15: img15,
        title15:"Interactive Inbox",
        text15:"Mark the replies from contacts as leads, follow-up, archive, and unread. Do much more than you can do on LinkedIn's messaging interface. Quickly sort your messaging inbox, stay organized and focused. Increase your productivity. Keep all your leads on your sight. Never miss a follow-up.",
    },

    {
        order: 'second',
        img1: img16,
        title1:"A/B test",
        text1:"A/B Test your messages. See what works best for you audience.",
        img2: img17,
        title2:"Export saved lists",
        text2:"Export all your lists. Use the data for retargeting, twitter handles, and analysis. Also, de-dupe with any existing lead lists, delete unwanted contacts, and import back as CSV.",
        img3: img18,
        title3:"Tag your contacts and messages",
        text3:"Create and add multiple tags for your contacts. Group your contacts. 'Tags' are great for highlighting prospects by categories like VIP, Hot Lead, Warm Lead, Agency client, industry, location, size, etc. You can get creative and create multiple tags relevant to you.",
        img4: img19,
        title4:"API & Zapier integrations",
        text4:"Sync We-Connect data with your CRM and other third party apps.",
        img5: img20,
        title5:"Schedule time zones of your preference",
        text5:"Schedule your campaigns based on time zones. Send messages at the right time when your prospects are most likely to be active on LinkedIn. Increase your connect and reply rates.",
        img6: img21,
        title6:"Email notifications",
        text6:"Get instant alerts when a prospect replies, the campaign starts or completes, and a captcha is detected, etc. Never miss a lead, respond timely, and save time with these email notifications. Unsubscribe if you do not want email notifications.",
        img7: img22,
        title7:"Saved replies",
        text7:"Save your commonly typed replies. Answer the frequently asked questions instantly. No need to copy-paste or rewrite the replies. Save time. Increase productivity.",
        img8: img23,
        title8:"Auto contact suppression",
        text8:"Linkedin searches can get difficult to track. Same contacts can show up again in a list you already used. We-Connect automatically suppresses duplicate contacts from same type of campaigns.",
        img9: img24,
        title9:"Archive campaigns",
        text9:"Reduce dashboard clutter. Archive completed campaigns rather than deleting. Review you campaigns at later date, avoid messaging duplicate contacts and reduce dashboard clutter.",
        img10: img25,
        title10:"Clone campaigns",
        text10:"Create a duplicate copy of your campaign with one click. All your campaign settings including the messaging content gets copied to a draft campaign. Just add a new saved search and launch your campaign.",
        img11: img26,
        title11:"Auto withdraw LinkedIn invites",
        text11:"Set up automatic withdrawl rule with your new invite campaigns. Don't let your pending invite number increase.",
        img12: img27,
        title12:"Blacklist contacts",
        text12:"Exclude unwanted contacts from your campaigns by easily blacklisting them.",
        img13: img28,
        title13:"Custom Variables (personalization - Send personalized messages)",
        text13:"Add a custom variable and use it to personalize your messaging.",
        img14: img29,
        title14:"Activity log",
        text14:"Be in control. We-Connect offers full transparency. Check anytme when the invites or messages were sent or will be sent. See the timestamp for each stage.",
        img15: img30,
        title15:"Write notes to your contact",
        text15:"Write anything you want to remember related to a particular contact like call notes, meeting notes, next steps, conversation history, discussion points, etc. It helps you see all the information in one place, save time, and be productive.",
    },
        
]


export default featuresData;