import React from 'react'
import Content from '../Styles/styleOne'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import img from '~assets/image/media/EmailAutomation/SVG/Double-Your-Conversion-with-Email-Automation.svg'

export default function Email({}){

return(
<Content backgroundColor="#F3F4F6">
  
  <Container>
    <Content.Block>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
    <Content.Image>
            <img  src={img} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
        </Content.Image>
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
      <Content.Box>
          <Content.SubText as="h2"> <SuperTag value="Email Automation"/> </Content.SubText>

          <Content.Text> <SuperTag value="Integrating various tools and platform is crucial for streamlining workflow and boosting productivity. Discover how We-Connect integration can revoluntionize your processes, saving you time and effort while maximizing results."/> </Content.Text>
          <Link to="/email-automation"><Content.Button  className="btn btn-primary text-white">Learn more</Content.Button></Link>
        </Content.Box>
      </Col>
    </Row>
    </Content.Block>

    
  </Container>
</Content>
)
}